<template>
  <c-step
    stepperGrpCd="RAM_RISK_STEP_CD"
    :currentStepCd="popupParam.ramStepCd"
    :param="param"
    v-model="ramStepCd"
    @stepBystep="stepBystep"
    @keySetting="keySetting"
    @research="research"
    @stepClick="stepClick"
    @closePopup="closePopup">
  </c-step>
</template>

<script>
import { uid } from 'quasar'
export default {
  name: 'riskDetail',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        ramRiskAssessmentPlanId: '',
        ramStepCd: '',
        returnRemark: '',
        ramTechniqueCd: '',
      }),
    },
  },
  data() {
    return {
      editable: true,
      ramStepCd: '',
      searchRiskReduce: {
        flag: '',
        isSearch: false,
      },
      searchScenario: {
        flag: '',
        isSearch: false,
      },
      planUpdateBtnData: {
        title: '계획',
        flag: false,
        research: '',
        planResearch: '',
      },
    };
  },
  watch: {
    'popupParam.ramStepCd'() {
      this.setStep();
    },
  },
  computed: {
    param() {
      return {
        ramRiskAssessmentPlanId: this.popupParam.ramRiskAssessmentPlanId,
        ramStepCd: this.popupParam.ramStepCd,
        returnRemark: this.popupParam.returnRemark,
        ramTechniqueCd: this.popupParam.ramTechniqueCd,
        searchRiskReduce: {
          flag: this.searchRiskReduce.flag,
          isSearch: this.searchRiskReduce.isSearch,
        },
        searchScenario: {
          flag: this.searchScenario.flag,
          isSearch: this.searchScenario.isSearch,
        },
        planUpdateBtnData: this.planUpdateBtnData
      }
    },
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      // role setting
      // url setting
      // code setting
      // list setting
      this.setStep();
    },
    setStep() {
      /**
       * RRS0000005 RRS0000010 단계는 같은 단계로 취급 
       * RRS0000005으로 데이터가 저장되지 않고 RRS0000010으로 저장
       * RRS0000010인 경우 팝업에서는 '평가실행'으로 표시되도록 RRS0000005으로 셋팅
       *  */ 
      if (this.popupParam.ramStepCd === 'RRS0000010') {
        this.ramStepCd = 'RRS0000005'
      } else {
        this.ramStepCd = this.$_.clone(this.popupParam.ramStepCd);
      }
    },
    stepBystep(stepCd) {
      this.popupParam.ramStepCd = stepCd
    },
    keySetting(key) {
      this.popupParam.ramRiskAssessmentPlanId = key
    },
    research(data) {
      if (data.place === 'scenario') {
        this.searchRiskReduce.isSearch = true;
      } else {
        this.searchScenario.isSearch = true;
      }
    },
    stepClick(step) {
      if (step === 'RRS0000010' && this.searchRiskReduce.isSearch) {
        this.searchRiskReduce.flag = uid();
        this.searchRiskReduce.isSearch = false;
      } else if (step === 'RRS0000005' && this.searchScenario.isSearch) {
        this.searchScenario.flag = uid();
        this.searchScenario.isSearch = false;
      }
    },
    closePopup() {
      this.$emit('closePopup')
    }
  }
};
</script>
